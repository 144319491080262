import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import gogetaHeadphones from '../../assets/images/gogeta-headphones.png';
import superImg from '../../assets/images/super-img.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';

export const Benefits = ({ scrollToInviteEmployer } : { scrollToInviteEmployer: () => void }) => {
	const { ref, controls } = useContentAnimation();

  const items: IBenefitsCard[] = [
    {
      title: 'Where can I buy my tech?',
      text: 'You can buy your tech at John Lewis, Currys or Apple (or all three!). You can save on a huge variety of home and personal tech, from fridges and washing machines to earpods and air tags, from all the best brands, including Apple, Samsung, Dyson and Sony.',
      image: gogetaHeadphones,
    },
    {
      title: 'How do I sign up?',
      text: "To use Gogeta Tech and save up to 8% on home and personal tech purchases, your employer needs to sign up. It's simple, quick and doesn't cost them a penny. Follow the link at the bottom of this page to get going.",
      image: superImg,
      onClick: scrollToInviteEmployer,
      buttonText: 'Let’s get started',
    },
  ];

	return (
		<ScBenefitsContainer>
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText, onClick }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
                onClick={onClick}
								buttonText={buttonText}
								inverted={index % 2 === 0}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
		</ScBenefitsContainer>
	);
};
