import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import {
	animationContainer,
	cardUp,
	fadeInLeft,
	fadeInRight,
	fadeInUp,
} from '../../animations/variants';
import image2 from '../../assets/images/ee-tech-card-1.png';
import image3 from '../../assets/images/ee-tech-card-2.png';
import image1 from '../../assets/images/ee-tech-card-3.png';
import { ScContainer } from '../../components/container/styled';
import { IImageTextCardItem, ImageTextCard } from '../../components/ImageTextCard';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import useWindowDimensions from '../../hooks/use-window-width';
import { ScHowItWorksItemsWrapper } from '../EmployeeCycling/styled';
import { ScTestimonialsWrapper } from '../Home/styled';

const items: IImageTextCardItem[] = [
	{
		title: 'Choose your<br/>tech',
		text: 'Browse John Lewis, Currys and Apple and choose your tech (you can be as geeky as you like with this part).',
		image: image2,
	},
	{
		title: 'Apply for your <br/>tech voucher',
		text: 'Visit your employer\'s application page and apply for a gift card for the amount you want to spend.',
		image: image1,
	},
	{
		title: 'Start spending',
		text: 'Once your employer approves your application, you\'ll get a gift card to spend online or' +
			' in-store. The cost will be spread over your next 12 months pay and you\'ll save up to 8%.',
		image: image3,
	},
];

export const HowItWorks = () => {
	const { ref, controls } = useContentAnimation();
	const { width } = useWindowDimensions();
	const theme = useTheme();

	return (
		<ScContainer backgroundColor={'rgb(255, 255, 255)'} maxWidth={'initial'}>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScTestimonialsWrapper style={{
					marginTop: 0
				}}>
					<MotionDiv variants={fadeInUp}>
						<ScTitle centered>How it works</ScTitle>
					</MotionDiv>
					<ScHowItWorksItemsWrapper ref={ref}>
						{items.map((item, index) => (
							<MotionDiv
								key={item.title}
								variants={width >= 1220 ? cardUp : index % 2 === 0 ? fadeInLeft : fadeInRight}
							>
								<ImageTextCard
									item={item}
									background={theme.colors.toasted}
									color={theme.colors.darkText}
									isSmallText
								/>
							</MotionDiv>
						))}
					</ScHowItWorksItemsWrapper>
				</ScTestimonialsWrapper>
			</motion.section>
		</ScContainer>
	);
};
